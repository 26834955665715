import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Col, Row } from 'reactstrap'
import {cx, css} from 'emotion'
import ProfileBar from "../components/profileBar";

class BlogPostTemplate extends React.Component {
  render() { 
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout headerType="blog" location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <Container className={css`
                margin-top: 30px;
                margin-bottom: 40px;
                padding-left: 30px;
                padding-right: 30px;
              `}>
             <Row>
                  <Col className={'col-12 col-lg-8 offset-lg-2 text-center'}>
                      <h1 className={css`
                        font-family: Nunito;
                        font-weight: 600;
                      `}>{post.frontmatter.title}</h1>
                      <p className={css`
                        margin-bottom: 40px;
                      `}>
                        {post.frontmatter.date}
                      </p>
                      {post.frontmatter.featuredImage && 
                        <>
                          <img src={post.frontmatter.featuredImage.childImageSharp.sizes.src} alt={post.frontmatter.imageCaption} width='100%' /> 
                          <em className={css`
                            display: block;
                            margin-top: 5px;
                            margin-bottom: 20px;
                            color: #afaeae;
                            font-family: Open Sans;
                            font-size: 14px;
                            `} >{post.frontmatter.imageCaption}</em> 
                        </>
                      }
                    </Col>
              </Row>
        </Container> 
        
        <Container className={css`
                margin-bottom: 80px;
                padding-left: 30px;
                padding-right: 30px;
              `}>
            <Row>
              <Col className={'col-12 col-lg-8 offset-lg-2 text-center'}>
                  <div className={cx('text-left',
                  css`
                    font-size: 1.2rem;
                    font-family: Open Sans;
                    img {
                      display: block;
                      margin: 20px 0 !important;
                      border: 1px solid #b9b9b9
                    }
                    em {
                      display: block;
                      margin: 40px;
                      font-size: 14px;
                      color: #666;
                      margin: 25px auto;
                      text-align: center;
                    }
                    h4 {
                      font-family: Nunito;
                      font-weight: 600;
                      margin-top: 20px;
                    }
                  `)} dangerouslySetInnerHTML={{ __html: post.html }} />
                  <ul
                    style={{
                      marginBottom: `80px`,
                      display: `flex`,
                      flexWrap: `wrap`,
                      justifyContent: `space-between`,
                      listStyle: `none`,
                      padding: 0,
                    }}
                  >
                    <li>
                      {previous && (
                        <Link to={`blog${previous.fields.slug}`} rel="prev">
                          ← {previous.frontmatter.title}
                        </Link>
                      )}
                    </li>
                    <li>
                      {next && (
                        <Link to={`blog${next.fields.slug}`} rel="next">
                          {next.frontmatter.title} →
                        </Link>
                      )}
                    </li>
                  </ul>
                  <ProfileBar />
              </Col>
            </Row>
          </Container>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(
      fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          childImageSharp{
              sizes(maxWidth: 630) {
                  ...GatsbyImageSharpSizes
              }
          }
        }
        imageCaption
      }
    }
  }
`