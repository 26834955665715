import React from "react"
import profilePic from "../assets/profile.jpg"
import { css, jsx } from "@emotion/core"

const ProfileBar = () => (
  <div
    className={jsx(
      "d-flex mb-1  p-4",
      css`
        background-color: #f5f5f5;
      `
    )}
  >
    <div>
      <img className={"rounded-circle mr-4"} src={profilePic} height="80px" />
    </div>
    <div className="text-left mt-2">
      Personal blog of <strong>Gus Higuera</strong> <br />
      Software Engineer, Designer and Comic Book creator in Austin, TX
    </div>
  </div>
)

export default ProfileBar
